<template>
  <div>
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-card-text>
            <h4>
              Update Iqama
            </h4>
          </b-card-text>
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(updateIqama)">
              <b-row>
                <!-- From -->
                <b-col cols="6">
                  <validation-provider
                    #default="validationContext"
                    name="From"
                    rules="required"
                  >
                    <b-form-group
                      label="From"
                      label-for="search"
                    >
                      <b-form-datepicker
                        id="from"
                        v-model="iqamaData.from"
                        class="mb-1"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- To -->
                <b-col cols="6">
                  <validation-provider
                    #default="validationContext"
                    name="To"
                    rules="required"
                  >
                    <b-form-group
                      label="To"
                      label-for="search"
                    >
                      <b-form-datepicker
                        id="to"
                        v-model="iqamaData.to"
                        class="mb-1"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Prayer -->
                <b-col cols="6">
                  <validation-provider
                    #default="validationContext"
                    name="Prayer"
                    rules="required"
                  >
                    <b-form-group
                      label="Prayer"
                      label-for="classification"
                    >
                      <b-form-select
                        v-model="iqamaData.iqamaName"
                        :options="iqamaList"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- time -->
                <b-col
                  cols="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Iqama Time"
                    rules="required"
                  >
                    <b-form-group
                      label="Iqama Time"
                      label-for="time"
                    >
                      <b-form-timepicker
                        v-model="iqamaData.iqamaTime"
                        locale="en"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="md"
                    variant="primary"
                    type="submit"
                  >
                    Update
                  </b-button>
                  <back />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'UpdateIqama',
  components: {
    Back,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert, showErrors } = handleAlerts()
    return {
      successfulOperationAlert,
      showErrors,
      getValidationState,
    }
  },
  data() {
    return {
      iqamaData: {
        id: this.$store.getters['mainEntity/getEntityId'],
        from: '',
        to: '',
        iqamaTime: '',
        iqamaName: '',
      },
      iqamaList: ['iqamahFajr', 'iqamahDhuhr', 'iqamahAsr', 'iqamahMaghrib', 'iqamahIsha'],
    }
  },
  methods: {
    updateIqama() {
      this.$http.post(`${process.env.VUE_APP_PRAYERS_BASE_URL}/update-iqama`, this.iqamaData).then(() => {
        this.successfulOperationAlert('Iqama is updated successfully')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
.form-control {
    border: 1px solid #d8d6de;
}
</style>
